"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ width: "100%", height: "100%", fill: "none", xmlns: "http://www.w3.org/2000/svg" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { d: "M32 35c0 1.3-.6 2.3-1.7 3a8 8 0 01-4.2 1c-2.3 0-4-.5-5.3-1.4-1.3-1.1-2-2.4-2-4L8 33.2c0 2.7.8 5.1 2.4 7.3 1.6 2.1 3.7 3.8 6.4 5 1.5.7 3 1.2 4.6 1.5l.3.1H5v-42h16.3a16.7 16.7 0 00-10.2 6A11 11 0 008.9 18c0 2.8.6 5 1.7 6.7 1.2 1.6 2.7 2.8 4.5 3.7 1.8.9 4.1 1.5 7 2.1 2.8.6 4.9 1 6.2 1.4 1.2.4 2.2.8 2.8 1.3.5.4.9 1 .9 1.9z", fill: "#0BB07D" }), (0, jsx_runtime_1.jsx)("path", { d: "M21.6 47.1h-.4.4zM47 47.1H30c1.6-.2 3.2-.8 4.7-1.4a15 15 0 006.1-5c1.5-2.1 2.3-4.5 2.3-7 0-2.8-.6-4.9-1.7-6.5a10.3 10.3 0 00-4.6-3.6c-1.9-.8-4.2-1.5-7-2-2.9-.6-4.9-1-6.1-1.4a7.8 7.8 0 01-2.8-1.2c-.5-.5-.8-1.1-.8-2 0-1.2.5-2.2 1.4-2.8 1-.7 2.3-1 3.8-1 1.8 0 3.3.5 4.3 1.4 1 .9 1.6 2 1.8 3.5H42a12 12 0 00-2.1-6.9A16.8 16.8 0 0029.6 5H47v42.1z", fill: "#383738" })] })));
}
exports.default = Svg;
